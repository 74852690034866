$sans: 'Gordita', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue',
  Helvetica, sans-serif;
$serif: 'Vollkorn', Georgia, serif;

body {
  font-family: $sans;
  margin: 0;
}

h1 {
  font-family: $sans;
  font-size: 3rem;
  font-weight: 700;
  margin: 0;
  margin-bottom: 2rem;
}

h2 {
  font-family: $sans;
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
}

h3 {
  font-family: $sans;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
}

h4 {
  font-family: $sans;
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
}

h5 {
  font-family: $sans;
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0;
}

blockquote {
  padding: 1rem;
  border-left: 3px solid #333;
  font-style: italic;
  margin-left: 0;
}

blockquote + blockquote {
  margin-top: -1.25rem;
}

pre {
  padding: 1rem;
  background-color: #eee;
  line-height: 1.5;
  margin: 0 0 1rem;
}

pre pre {
  padding: 0;
  margin: 0;
}

pre + pre {
  padding-top: 0;
}

pre code {
  display: block;
}

img {
  max-width: 100%;
}

figure {
  margin: 0;
  padding: 0;
}

p {
  font-size: 16px;
  line-height: 1.75;
  font-weight: 400;
}

a,
a:hover {
  color: inherit;
  text-decoration: none;
}

*::selection {
  background-color: #fff0d4;
  color: #222;
}

*::-moz-selection {
  background-color: #fff0d4;
  color: #222;
}

@media screen and (max-width: $phone-landscape-max) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 0.875rem;
  }
}
