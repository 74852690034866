.Toasts {
  position: fixed;
  bottom: 1.5rem;
  left: 1.5rem;
  z-index: 5000;
}

.Toast {
  box-shadow: 0 4px 24px 0 rgba($true-black, 0.05);
  padding: 1rem 1.5rem;
  z-index: 5000;
  background-color: $black;
  color: $white;
  margin-top: 1rem;
  border-radius: 4px;
  transform: translate3d(0, 300%, 0);
  transition: 0.2s ease transform;
}

.Toast-button {
  margin-left: 1rem;
}

.Toast.Toast-active {
  transform: none;
}

@media screen and (max-width: $tablet-landscape-max) {
  .Toasts {
    left: 1rem;
  }
}
