body {
  padding-top: 5rem;
}

.Navbar {
  height: 5rem;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  background-color: $white;
  display: flex;
  align-items: center;
  user-select: none;

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul li {
    margin: 0 1rem;
  }

  ul li a {
    color: inherit;
    text-decoration: none;
  }
}

.Navbar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  max-width: 71rem;
}

.Navbar-logo {
  margin: 0;
  height: 22px;
}

.Navbar-center {
  width: 100%;
  max-width: 47rem;
}

.Navbar-right,
.Navbar-left {
  flex-shrink: 0;
  flex-grow: 1;
}

.Navbar .Navbar-spacer {
  flex: 1;
  margin: 0;
}

.AccountBadge {
  position: relative;
  display: flex;
  align-items: center;
}

.AccountBadge-navbar {
  display: flex;
}

.AccountBadge-navbar img {
  margin-right: 1rem;
}

.AccountBadge img {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
}

.AccountBadge-navbar span {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
  white-space: nowrap;
}

.AccountBadge-card {
  transform-origin: top right;
  position: absolute;
  right: 0;
  top: 0;
  padding: 1rem;
  background: $white;
  box-shadow: 0 2px 4px rgba(34, 34, 34, 0.1);
  border-radius: 8px;
  transform: translate3d(0, -1rem, 0);
  opacity: 0;
  pointer-events: none;
  transition: 0.2s ease transform;
  width: 20rem;
}

.AccountBadge-empty {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background: $light-gray;
}

.AccountBadge-card:hover,
.AccountBadge-navbar:hover + .AccountBadge-card {
  transform: none;
  opacity: 1;
  pointer-events: auto;
}

.AccountBadge-card p {
  font-family: $sans;
  font-weight: 400;
  margin: 0;
}

.AccountBadge-info {
  display: inline-flex;
  align-items: center;
  margin-bottom: 1rem;
}

.AccountBadge-info img {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}

@media screen and (max-width: 640px) {
  .AccountBadge-navbar span {
    display: none;
  }

  .AccountBadge-navbar img {
    margin-right: 0;
  }
}
