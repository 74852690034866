$true-black: #000;
$black: #222;
$gray: #666;
$light-gray: #ccc;
$lighter-gray: #eee;
$white: #fff;
$transparent: transparent;

$orange: #ffc44c;
$green: #24b379;
$red: #b32424;
$blue: #3d79cc;
$light-blue: #7aa3dc;
