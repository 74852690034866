.PrefixTextbox {
  position: relative;
  border: 1px solid $lighter-gray;
  width: 100%;
  display: block;
  overflow: hidden;
  margin: 1rem 0;
  border-radius: 4px;

  span {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    opacity: 0;
    transition: 0.1s linear opacity;
  }

  .text-input {
    padding: 1rem;
    width: 100%;
    transition: 0.2s ease transform;
    border: 0;
    outline: none;
    resize: none;
  }

  .text-input,
  span {
    font-family: $sans;
    font-size: 1rem;
  }

  .text-input:focus,
  &.with-text .text-input {
    opacity: 1;
    transition-duration: 0.1s;
  }

  &.with-text span,
  .text-input:focus + span {
    opacity: 1;
    transition-duration: 0.2s;
  }
}
