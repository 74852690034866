.HomePageBanner {
  height: 0;
  position: relative;
  background-size: cover;
  background-position: center;
  border-radius: 8px;

  &.signed-out {
    padding-bottom: 43.5%;
  }

  &.signed-in {
    padding-bottom: 28.3%;
  }

  .image-button {
    margin-left: 1rem;
  }
}

.HomePageBanner-text-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 80%;
  color: $white;
  text-shadow: 0 0 3rem rgba($true-black, 0.5);
  z-index: 2;

  h3 span {
    margin-right: 1rem;
  }
}

@media screen and (max-width: $tablet-portrait-max) {
  .HomePageBanner h1 {
    font-size: 2rem;
  }
}

@media screen and (max-width: $phone-landscape-max) {
  .HomePageBanner {
    h1 {
      font-size: 1.5rem;
    }

    h3 {
      font-size: 1.1rem;
      display: flex;
      align-items: center;
      flex-direction: column;

      .text-button {
        font-size: 0.875rem;
        display: block;
        margin-top: 1rem;
      }

      span {
        margin-right: 0;
      }
    }
  }

  .HomePageBanner.signed-in {
    padding-bottom: 50%;
  }

  .HomePageBanner.signed-out {
    padding-bottom: 100%;
  }
}
