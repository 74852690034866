.Editor-wrapper {
  flex: 1;
}

.content {
  width: 100%;
  max-width: 45rem;
  margin: 0 auto;
  flex: 1;
  box-sizing: border-box;
  font-size: 1rem;
  padding: 3rem 0;
  line-height: 1.6;
  opacity: 1;
  transition: 0.2s ease opacity;
}

.content.content-loading {
  opacity: 0;
}

.Editor {
  margin-bottom: 1rem;
  position: relative;
  font-size: 1.25rem;
  font-family: $serif;

  p,
  .p {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  ul li,
  ol li {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }

  h2,
  h3 {
    margin: 3rem 0 2rem;
  }

  img {
    border-radius: 8px;
  }

  input {
    font-family: $sans;
  }
}

.Editor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.buttons {
  padding: 0.5rem 0;
  display: flex;
}

.buttons .spacer {
  flex: 1;
}

.buttons button,
.buttons .button {
  margin-right: 0.5rem;
}

.Editor h1 {
  line-height: 4rem;
}

.Editor h1 input {
  height: 4rem;
  border: 0;
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  outline: none;
  width: 100%;
}

.Editor h1 input::placeholder,
.Editor::placeholder {
  color: $light-gray;
}

// Part of Draft.js that makes it clickable
.DraftEditor-root > div > div {
  padding-bottom: 8rem;
}

.Editor-toolbar {
  border-top: 1px solid $lighter-gray;
  background-color: $white;
  z-index: 100;
  padding-bottom: 0.5rem;
}

.PostMetadata {
  margin: 2rem 0;
  display: flex;
  align-items: center;
  transition: 0.3s ease opacity;
}

.Editor .PostMetadata {
  opacity: 0.46;
}

.PostMetadata.empty {
  opacity: 0.24;
}

.PostMetadata img {
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
}

.Editor .PostMetadata img {
  filter: grayscale(1);
}

.PostMetadata h4 {
  margin: 0 1rem;
  color: $gray;
}

.hero-image {
  margin-bottom: 3rem;
}

.Post-content,
.Editor {
  .place-link,
  .link {
    background-image: linear-gradient(
      to top,
      $transparent 0%,
      $transparent 50%,
      rgba($blue, 0.5) 51%,
      rgba($blue, 0.5) 100%
    );
    background-size: 100% 4px;
    background-position: bottom;
    background-repeat: no-repeat;
    text-decoration: none;
    border-bottom: 0;
  }
}

.drafting-indicator {
  font-style: italic;
  padding: 0.5rem 1rem;
  background-color: rgba($true-black, 0.1);
}
