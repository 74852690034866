.PlaceButton svg {
  fill: $gray;
  stroke: $gray;
}

.PlaceForm-wrapper {
  width: 25rem;
}

.PlaceForm {
  position: relative;
}

.PlaceForm svg {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  pointer-events: none;
  left: 1rem;
  top: 0.5rem;
  fill: $blue;
  stroke: $blue;
}

.PlaceForm input {
  box-sizing: border-box;
  padding: 0.5rem 1rem 0.5rem 3rem;
  line-height: 1.5rem;
  font-size: 0.875rem;
  border: 0;
  outline: none;
  border-radius: 8px;
  width: 100%;
}

.suggestion-item,
.suggestion-item--active,
.autocomplete-dropdown-container-loading {
  font-size: 0.875rem;
  font-family: 'Gordita', 'Roboto', 'Helvetica Neue', Helvetica, sans-serif;
  padding: 0.25rem 0;
}

.suggestion-item--active {
  background-color: $lighter-gray;
}

.autocomplete-dropdown-container {
  border-top: 1px solid $lighter-gray;
  padding: 0.25rem 0 0.5rem;
  margin: 0 1rem;
}
