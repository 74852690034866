.inline-toolbar {
  background: $white;
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba($true-black, 0.1);
  box-sizing: border-box;
  left: 50%;
  position: absolute;
  transform: translate(-50%) scale(0);
  z-index: 2;
}

.inline-toolbar::after,
.inline-toolbar::before {
  background-color: $white;
  content: ' ';
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 100%;
}

.inline-toolbar::after {
  height: 10px;
  width: 20px;
  transform: translate(-50%, -100%);
  z-index: 2;
}

.inline-toolbar::before {
  height: 10px;
  transform: translate(-50%, -5px) rotate(45deg);
  width: 10px;
  z-index: 1;
}

.inline-toolbar-button-wrapper {
  display: inline-block;
}

.inline-toolbar-button {
  color: $gray;
  font-size: 1rem;
  font-weight: 700;
  font-family: $sans;
  border: 0;
  padding: 0.5rem;
  vertical-align: bottom;
  height: 2.5rem;
  width: 2.5rem;
  box-sizing: border-box;
  border-radius: 8px;
}

.inline-toolbar-button-wrapper:first-of-type .inline-toolbar-button {
  font-weight: 800;
}

.inline-toolbar-button svg {
  fill: $gray;
}

.inline-toolbar-button:hover,
.inline-toolbar-button:focus {
  cursor: pointer;
  outline: 0; /* reset for :focus */
}

.inline-toolbar-button-active {
  color: $blue;
}

.inline-toolbar-button-active svg {
  fill: $blue;
}

.draftJsToolbar__separator__3U7qt {
  margin-bottom: -3px;
}
