.TitleBar {
  padding: 1.5rem 0 0;
}

.TitleBar h1 {
  margin: 4.5rem 0 1.5rem;
}

.TitleBar-menu {
  border-bottom: 1px solid $black;
  margin: 0 -2rem;
}

.TitleBar-menu ul {
  display: flex;
  margin: 0;
  padding: 0 2rem;
}

.TitleBar-menu li {
  display: flex;
  padding: 0.5rem 1.5rem;
}

.TitleBar-menu li.is-selected {
  border-bottom: 2px solid $black;
}
