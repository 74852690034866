.PostListItem {
  display: flex;
  border-bottom: 1px solid $light-gray;
  padding: 1.5rem 0;
}

.PostListItem:hover h3 {
  text-decoration: underline;
}

.PostListItem .image {
  height: 4rem;
  width: 4rem;
  background-position: center;
  background-size: cover;
  margin-right: 1.5rem;
}

.PostListItem .details {
  flex: 1;
}

.PostListItem .details p {
  margin: 0.75rem 0 0;
}
