button {
  border: 0;
  outline: 0;
}

.button {
  background-color: transparent;
  border-radius: 0;
  display: inline-block;
  font-family: $sans;
  font-weight: 600;
  padding: 0 0.5rem;
  height: 2rem;
  line-height: 2;
  position: relative;
  outline: none;
  border: 0;
  font-size: 1rem;
  cursor: pointer;
  flex-shrink: 0;
  user-select: none;
  transition: 0.2s ease all;

  &::after {
    background-color: $black;
    border-radius: 2px;
    bottom: 0;
    content: ' ';
    left: 0;
    opacity: 0.05;
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: bottom center;
    transform: scale(1, 0.5);
    transition: 0.2s ease all;
  }

  &.orange::after {
    background-color: $orange;
    opacity: 0.25;
  }

  &.green::after {
    background-color: $green;
    opacity: 0.15;
  }

  &.red::after {
    background-color: $red;
    opacity: 0.2;
  }

  &.button-disabled {
    cursor: not-allowed;
    color: $light-gray;

    &::after {
      background-color: $black;
      opacity: 0.05;
    }

    &:hover::after {
      transform: scale(1, 0.5);
    }
  }

  &:hover,
  &:focus {
    background: transparent;

    &::after {
      transform: none;
      border-radius: 4px;
    }
  }
}

.buttons {
  .button {
    margin-right: 2rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.button-text {
  font-weight: 700;
  cursor: pointer;
}

.text-button {
  border-radius: 4px;
  color: $blue;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5;
  cursor: pointer;
  transition: 0.3s ease color;

  &:hover {
    color: $light-blue;
  }

  &.green {
    color: $green;
  }

  &.with-background {
    background-color: $white;
    padding: 0.5rem 1rem;
    text-shadow: none;
  }
}

.icon-button {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  margin-right: 3rem;
  display: flex;
  align-items: center;
  border-bottom: 0;
  text-decoration: none;
  color: $gray;
  cursor: pointer;
  transition: 0.2s linear color;

  &:last-child {
    margin-right: 0;
  }

  svg {
    height: 1.5rem;
    margin-right: 1rem;
    width: 1.5rem;

    g,
    path,
    circle {
      stroke: $gray;
      transition: 0.2s linear stroke;
    }
  }

  &:hover {
    color: $blue;

    svg {
      g,
      path,
      circle {
        stroke: $blue;
      }
    }
  }
}
