.LinkButton svg g {
  fill: $gray;
}

.LinkButton-wrapper {
  width: 25rem;
  position: relative;
}

.LinkButton-wrapper svg {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  pointer-events: none;
  left: 1rem;
  top: 0.5rem;
}

.LinkButton-wrapper svg g {
  fill: $blue;
}

.LinkButton-wrapper input {
  box-sizing: border-box;
  padding: 0.5rem 1rem 0.5rem 3rem;
  line-height: 1.5rem;
  font-size: 0.875rem;
  border: 0;
  outline: none;
  border-radius: 8px;
  width: 100%;
}
