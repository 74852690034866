.Footer {
  color: $black;
  display: flex;
  padding: 4rem 0;

  h4 {
    color: $gray;
  }

  .spacer {
    flex: 1;
  }

  .mdot {
    padding: 0 0.5rem;
  }
}
