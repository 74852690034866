.Home-posts {
  display: flex;
  flex-wrap: wrap;
  margin: 3rem -2rem;
}

.Home-posts > * {
  padding: 1rem 2rem;
  width: 50%;
}

@media screen and (max-width: $tablet-portrait-max) {
  .Home-posts > * {
    width: 100%;
  }
}

@media screen and (max-width: $phone-landscape-max) {
  .hide-mobile {
    display: none;
  }
}
