.User-content {
  padding: 4rem 0;
}

.User-info {
  max-width: 45rem;
  display: flex;
  padding-bottom: 4rem;
}

.User-info-details {
  width: 100%;
}

.User-profile-photo {
  width: 9rem;
  height: 9rem;
  border-radius: 50%;
  margin-right: 3rem;
}

.User-content > h2 {
  margin-bottom: 1.5rem;
}

.User-posts {
  display: flex;
  flex-wrap: wrap;
  margin: 4rem -2rem;
}

.User-posts > * {
  padding: 1rem 2rem;
  width: 50%;
}

@media screen and (max-width: $tablet-portrait-max) {
  .User-profile-photo {
    width: 4rem;
    height: 4rem;
  }
}

@media screen and (max-width: $phone-landscape-max) {
  .User-profile-photo {
    width: 3rem;
    height: 3rem;
    position: absolute;
  }

  .User-info-details h1 {
    padding-left: 4.5rem;
  }
}
