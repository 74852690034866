.PlaceTagSuggestionsWrapper > div {
  display: inline-block;
  box-shadow: 0 4px 24px 0 rgba($true-black, 0.05);
  background-color: $white;
  margin: 0 -0.5rem;
  padding: 0 1.75rem 0.5rem;
  border-radius: 4px;
  position: absolute;
  z-index: 1000;
  max-width: 40rem;
}

.PlaceTagSuggestionsEntry {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  strong {
    margin-right: 1rem;
  }

  span {
    color: $gray;
  }

  &.is-focused {
    background-color: $lighter-gray;
  }
}
