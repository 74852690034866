body.is-CityEditor-open {
  overflow: hidden;
}

.CityEditor {
  position: fixed;
  z-index: 50;
  left: 0;
  right: 0;
  top: 0;
  min-height: 100%;
  background-color: rgba($white, 0.75);
  opacity: 0;
  pointer-events: none;
  transition: 0.3s ease opacity;
}

.CityEditor form {
  position: absolute;
  display: flex;
  padding: 3rem;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba($true-black, 0.1);
}

.CityEditor.CityEditor-active {
  opacity: 1;
  pointer-events: auto;
}

.CityEditor input {
  font-family: 'Gordita', 'Roboto', 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  padding: 0;
  outline: 0;
  line-height: 1.6;
  transform: scale();
  border: 0;
  box-sizing: border-box;
}

.CityEditor .autocomplete-dropdown-container {
  background: $white;
  margin: 0 -1rem;
  padding: 0 1rem;
}

.CityEditor .autocomplete-dropdown-container .suggestion-item {
  padding: 0.25rem 0;
}

.Picker {
  z-index: 1000;
}

.CityEditor p {
  margin-top: 1.5rem;
}
