.ProgressiveImageGrid {
  display: flex;
  margin: 2rem -0.5rem;
}

.ProgressiveImage {
  max-width: 100%;
  border-radius: 8px !important;
  position: relative;
  overflow: hidden;

  img {
    display: block;
  }

  &.display-preview img {
    filter: blur(8px);
  }
}

.ProgressiveImageGrid .ProgressiveImage {
  margin: 0 0.5rem;
}
