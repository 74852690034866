@import '_media_queries.scss';
@import '_colors.scss';
@import '_fonts.scss';
@import '_typography.scss';
@import 'components/_App.scss';
@import 'components/_Button.scss';
@import 'components/_City.scss';
@import 'components/_CityEditor.scss';
@import 'components/_CoverImageEditor.scss';
@import 'components/_Editor.scss';
@import 'components/_Footer.scss';
@import 'components/_Home.scss';
@import 'components/_HomePageBanner.scss';
@import 'components/_InlineToolbar.scss';
@import 'components/_LinkButton.scss';
@import 'components/_Navbar.scss';
@import 'components/_NotFound.scss';
@import 'components/_PlaceButton.scss';
@import 'components/_PlaceTagSuggestions.scss';
@import 'components/_Post.scss';
@import 'components/_PostAuthor.scss';
@import 'components/_PostCard.scss';
@import 'components/_PostList.scss';
@import 'components/_PostListItem.scss';
@import 'components/_PostRelatedContent.scss';
@import 'components/_Posts.scss';
@import 'components/_PrefixTextbox.scss';
@import 'components/_ProgressiveImageGrid.scss';
@import 'components/_TitleBar.scss';
@import 'components/_Toasts.scss';
@import 'components/_User.scss';

*,
*::after,
*::before {
  box-sizing: border-box;
}

body html,
#root {
  height: 100%;
  font-size: 16px;
  color: $black;
}
