.PostAuthor a {
  display: flex;
  align-items: center;
}

.PostAuthor .PostAuthor-profile {
  border-radius: 50%;
}

.PostAuthor h4 {
  color: $black;
}
