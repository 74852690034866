.CoverImageEditor {
  height: 0;
  padding-bottom: 21.66%;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 3rem;
  position: relative;
}

.CoverImageEditor.display-preview {
  transition: 0.5s ease padding-bottom;
}

.CoverImageEditor .cover-preview,
.CoverImageEditor .cover-full {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  transition: 0.3s ease opacity;
  background-color: $light-gray;
  border-radius: 8px;
}

.cover-preview img,
.cover-full h4 {
  position: relative;
  z-index: 50;
}

.cover-preview h4,
.cover-full h4 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: $white;
  text-shadow: 0 0 3rem $true-black;
  z-index: 100;
}

.CoverImageEditor.display-preview .cover-preview {
  opacity: 1;
}

.CoverImageEditor.display-preview .cover-full {
  opacity: 0;
}

.CoverImageEditor.display-full .cover-preview {
  opacity: 0;
}

.CoverImageEditor.display-full .cover-full {
  opacity: 1;
}

.CoverImageEditor .cover-preview img {
  filter: blur(8px);
}

.dropzone {
  border: 1px solid $light-gray;
  border-radius: 8px;
  height: 100%;
  max-height: 9.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $light-gray;
  cursor: pointer;
  user-select: none;
  transition: 0.2s ease all;
  margin-bottom: 3rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.dropzone h4 {
  position: relative;
  margin-left: 0.5rem;
  width: 8rem;
  transition: 0.2s ease color;
  height: 1.5rem;
}

.dropzone h4 .default,
.dropzone h4 .dragging {
  position: absolute;
  transition: 0.3s ease all;
  white-space: nowrap;
}

.dropzone h4 .dragging {
  left: 0;
  opacity: 0;
  transform: translate3d(0, 1rem, 0);
  color: $green;
}

.dropzone svg g circle,
.dropzone svg g path {
  transition: 0.2s ease all;
}

.dropzone--isActive svg g circle,
.dropzone--isActive svg g path {
  stroke: $green;
}

.dropzone.dropzone--isActive {
  border-color: $green;
}

.dropzone.dropzone--isActive h4 .default {
  opacity: 0;
  transform: translate3d(0, -1rem, 0);
}

.dropzone.dropzone--isActive h4 .dragging {
  opacity: 1;
  transform: none;
}

@media screen and (max-width: $phone-landscape-max) {
  .CoverImageEditor {
    padding-bottom: 50%;
    margin-bottom: 1.5rem;
  }
}
