.PostRelatedContent {
  padding-top: 4rem;
  border-top: 1px solid $light-gray;
  margin-bottom: 4rem;
}

.PostRelatedContent h2 {
  width: 100%;
  margin: 4rem 0 3rem;
  display: flex;
  align-items: center;
}

.PostRelatedContent h2:first-child {
  margin-top: 0;
}

.PostRelatedContent .PostRelatedContent-profile {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.PostRelatedContent .PostList {
  margin-top: 0;
  margin-bottom: 0;
}
