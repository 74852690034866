.PostCard {
  border-radius: 8px;
  box-shadow: 0 4px 24px 0 rgba($true-black, 0.075);
  box-sizing: content-box;
  display: flex;
  height: 12rem;
  transition: 0.3s ease all;
  position: relative;

  .image {
    background-color: $light-gray;
    background-position: center;
    background-size: cover;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    flex-shrink: 0;
    height: 12rem;
    width: 12rem;
  }

  .details {
    padding: 1.5rem;
    flex-direction: column;
    display: flex;

    h3 {
      flex: 1;
    }
  }

  .author {
    display: flex;
    align-items: center;

    img {
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 50%;
      margin-right: 1rem;
    }
  }

  &:hover {
    box-shadow: 0 4px 24px 0 rgba($true-black, 0.125);
    cursor: pointer;
    transform: scale(1.015);
  }

  .Post-edit-button {
    position: absolute;
    right: 1rem;
    bottom: 1.5rem;
  }

  &.large {
    padding: 1.5rem;

    .image {
      border-radius: 8px;
      margin-right: 1.5rem;
    }

    .details {
      padding: 0 1.5rem 0 0;

      h3 {
        flex: 0;
        margin-bottom: 1.5rem;
        padding-right: 1.5rem;
      }
    }

    .Post-edit-button {
      top: 1.5rem;
      bottom: auto;
    }
  }
}

@media screen and (max-width: $phone-landscape-max) {
  .PostCard {
    height: 8rem;

    .image {
      height: 8rem;
      width: 8rem;
    }
  }
}
