.PostList {
  list-style: none;
  padding: 0;
}

.PostList > div {
  width: 50%;
}

.PostList-Cards {
  display: flex;
  flex-wrap: wrap;
  margin: 4rem -2rem 2rem;
}

.PostList-Cards > * {
  padding: 1rem 2rem;
  width: 50%;
}

.PostList-Cards.large > * {
  width: 100%;
  max-width: 45rem;
}

@media screen and (max-width: $tablet-landscape-max) {
  .PostList-Cards > * {
    width: 100%;
    padding: 1rem 2rem;
  }
}
