.City {
  box-shadow: 0 4px 16px 0 rgba($true-black, 0.1);
  border-radius: 8px;
  transition: 0.3s ease all;
  position: relative;
  padding: 3rem;
}

.City-map {
  border-radius: 50%;
  flex-shrink: 0;
  height: 9rem;
  margin-right: 3rem;
  width: 9rem;
}

.City-map-inner {
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  clip-path: circle(4.5rem at 7.5rem 7.5rem);
  transition: 0.7s ease all;
  z-index: 10;
  border-radius: 8px;
  overflow: hidden;
}

.City-map:hover .City-map-inner {
  clip-path: circle(50rem at 7.5rem 7.5rem);
}

.City-scrim {
  background: rgba($white, 0.8);
  bottom: 0;
  cursor: pointer;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s ease opacity;
  display: flex;
  align-items: center;
  justify-content: center;
}

.City-scrim h3 {
  opacity: 0;
  transform: translate3d(0, 1rem, 0);
  transition: 0.3s ease all;
}

.City:hover {
  /* box-shadow: 0 0 1.5rem 0 rgba($true-black, 0.1); */
}

.City:hover .City-scrim {
  opacity: 1;
  z-index: 100;
}

.Editor .City-details,
.Post .City-details {
  p {
    margin-bottom: 0;
  }

  h3 {
    margin-bottom: 1.5rem;
    margin-top: 0;
    padding-right: 6rem;
  }
}

.City:hover .City-scrim h3 {
  opacity: 1;
  transform: none;
}

.City.is-editing .City-scrim {
  display: none;
}

.City-details {
  position: relative;
  flex: 1;
}

.City-details p {
  display: block;
  min-height: 5.5rem;
  max-height: 5.5rem;
  overflow: hidden;
  position: relative;
  margin: 0;
  line-height: 1.5;
}

.City-details .read-more {
  position: absolute;
  color: $blue;
  right: 0;
  bottom: 0;
  line-height: 1.5rem;
  font-size: 1rem;
  background-color: $white;
  font-family: $sans;
  text-decoration: none;
  transition: 0.3s linear opacity;
  cursor: pointer;
}

.City-details .read-more::before {
  content: ' ';
  background:
    linear-gradient(
      to right,
      rgba($white, 0) 0%,
      rgba($white, 1) 75%,
      rgba($white, 1) 100%
    );
  position: absolute;
  display: block;
  right: 100%;
  top: 0;
  transform: translateX(1px);
  transition: 0.2s ease opacity;
  height: 2rem;
  width: 5rem;
}

.City.City-in-editor-context .City-details {
  pointer-events: none;
}

.City-preview {
  display: flex;
  position: relative;
  overflow: hidden;
  margin: -3rem;
  padding: 3rem;
}

.City-is-expanded .City-preview {
  padding-bottom: 3rem;
  margin-bottom: -3rem;
  border-bottom: 1rem solid $white;
  box-sizing: border-box;
}

.City-toggle {
  float: right;
  position: absolute;
  transition: 0.3s ease transform;
  top: 0;
  right: 0;
  cursor: pointer;
}

.City-related {
  max-height: 0;
  transition: 0.3s ease max-height;
  overflow: hidden;
  height: 19rem;
}

.City .City-related-item {
  height: 9.5rem;
  padding-top: 3rem;
}

.City .City-details p {
  max-height: 5.5rem;
  transition: 0.3s ease max-height;
}

.City.City-is-expanded .City-related {
  max-height: 19rem;
}

.City.City-is-expanded .City-toggle {
  transform: rotate(180deg);
}

.City.City-is-expanded .City-details p {
  max-height: 12rem;
}

.City.City-is-expanded .read-more {
  opacity: 0;
}

.CityRelated {
  display: flex;
}

.CityRelated-header {
  width: 9rem;
  margin-right: 3rem;
}

.CityRelated-header p > a,
.CityRelated-header p > a:hover {
  color: $blue;
  font-family: $sans;
  font-size: 0.8rem;
  text-decoration: none;
  cursor: pointer;
}

.CityRelated-scroll-wrapper {
  width: calc(100% - 12rem);
}

.CityRelated-scroller {
  display: flex;
  overflow: scroll;
  padding: 0;
  margin: 0;
}

.CityRelated-item {
  flex-shrink: 0;
  list-style-type: none;
  width: 9rem;
  display: inline-block;
  height: 6rem;
  margin-right: 3rem;
  background-image: url('https://static.schlosser.io/danrs.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.CityRelated-item:last-child {
  margin-right: 0;
}
