.Post {
  article {
    margin: 0 auto;
    max-width: 45rem;
  }

  img {
    border-radius: 8px;
  }

  .Post-cover-image {
    margin: 0 0 3rem;
    padding: 0;
    display: block;
    position: relative;

    .Post-edit-button {
      position: absolute;
      right: 1rem;
      bottom: 0;
      margin-bottom: -6rem;
    }

    img {
      width: 100%;
    }
  }

  .buttons {
    padding: 4rem 0;
    display: flex;
  }

  .PostMetadata {
    .icon-button {
      margin-right: 0.5rem;
    }

    .date {
      margin-right: 3rem;
    }
  }
}

.Post-content {
  font-size: 1.25rem;
  font-family: $serif;

  a {
    border-bottom: 2px solid $blue;
    text-decoration: none;
  }

  p,
  .p {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  ul li,
  ol li {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }

  h2,
  h3 {
    margin: 3rem 0 2rem;
  }
}
